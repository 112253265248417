
@media (max-width:1200px){
    .home{
        width: 100%;
        .banner{
            position: relative;
            height: 5.76rem;
            background: url(../assets/banner_line.png) 100% 100% no-repeat;
            background-color: #FFDEEF;
            background-size: cover;
            .banner_title{
                position: absolute;
                width: 100%;
                height: 0.42rem;
                background: #FFFFFF;
                opacity: .7;
                z-index: 9;
            }
            .introduce{
                width: 100%;
                position: absolute;
                top: .922rem;
                h1{
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 0.12rem;
                    height: 0.307rem;
                    font-size: 0.28rem;
                    font-family: Poppins-Bold, Poppins;
                    font-weight: normal;
                    color: #2B2B2B;
                    line-height: 0.307rem;
                }
                p{
                    width: 100%;
                    height: 0.96rem;
                    padding: 0 0.192rem;
                    font-size: 0.144rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 0.192rem;
                    text-align: center;
                    margin-bottom: 0.144rem;
                    opacity: 0.8;
                }
                .download{
                    display: none;
                    position: absolute;
                    left: 0.84rem;
                    font-size: 0.134rem;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: left;
                    z-index: 9;
                    .download_ios{
                        display: block;
                        width: 1.92rem;
                        height: 0.384rem;
                        line-height: 0.384rem;
                        background: #000000;
                        border-radius: 0.211rem;
                        margin: auto;
                        margin-bottom: 0.096rem;
                        text-align: center;
                        img{
                            width: 0.182rem;
                            height: 0.182rem;
                            margin-top: -0.048rem;
                            margin-right: 0.057rem;
                        }
                    }
                }
                .m_download{
                    display: block;
                    position: absolute;
                    left: 0.84rem;
                    font-size: 0.134rem;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: left;
                    z-index: 9;
                    .download_ios{
                        display: block;
                        width: 1.92rem;
                        height: 0.384rem;
                        line-height: 0.384rem;
                        background: #000000;
                        border-radius: 0.211rem;
                        margin: auto;
                        text-align: left;
                        padding-left: 0.192rem;
                        img{
                            width: 0.182rem;
                            height: 0.182rem;
                            margin-top: -0.048rem;
                            margin-right: 0.057rem;
                        }
                    }
                    .download_android{
                        display: block;
                        width: 1.92rem;
                        height: 0.384rem;
                        line-height: 0.384rem;
                        background: #09B55F;
                        border-radius: 0.211rem;
                        margin: auto;
                        margin-bottom: 0.096rem;
                        text-align: left;
                        padding-left: 0.192rem;
                        img{
                            width: 0.182rem;
                            height: 0.182rem;
                            margin-top: -0.048rem;
                            margin-right: 0.057rem;
                        }
                    }
                    .download_android:hover{
                        cursor: pointer;
                    }
                    .download_ios:hover{
                        cursor: pointer;
                    }
                }
            }
            .main{
                // width: 1200px;
                width: 100%;
                margin: 0 auto;
                .top{
                    width: 100%;
                    height: 0.42rem;
                    padding: 0 0.192rem;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    z-index: 99;
                    .left_logo{
                        display: inline-block;
                        width: 1.1rem;
                        height: 0.278rem;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .contact_us{
                        width: 1.114rem;
                        height: 0.307rem;
                        background: #FF5BAC;
                        border-radius: .32rem;
                        font-size: 0.12rem;
                        font-family: Poppins-Medium, Poppins;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 0.32rem;
                    }
                    .right_logo{
                        display: inline-block;
                        position: absolute;
                        top: 0.135rem;
                        width: 0.758rem;
                        height: .178rem;
                        margin-left: 12px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .pc_top{
                    display: none;
                }
                .pic_1_div{
                    position: absolute;
                    top: 2.88rem;
                    left: 0;
                    width: 100%;
                    height: 2.88rem;
                    background: url('../assets/m_banner.png') 100% 100% no-repeat;
                    background-size: cover;
                    img{
                        display: none;
                    }
                }
            }
        }
        .contain{
            background: #FFFFFF;
            // height: 5.34rem;
            height: 5.056rem;
            background:url(../assets/m_introduce_top.png) 100% 100% no-repeat;
            background-size: cover;
            .main{
                width: 100%;
                margin: 0 auto;
                position: relative;
                .heart{
                    display: none;
                }
                .introduce{ 
                    position: absolute;
                    width: 100%;
                    top: 0.48rem;
                    padding: 0 0.192rem;
                    text-align: center;
                    .introduce_m{
                        display: block;
                    }
                    .introduce_pc{
                        display: none;
                    }
                    h1{
                        width: 100%;
                        height: 0.307rem;
                        font-size: 0.28rem;
                        font-family: Poppins-Bold, Poppins;
                        font-weight: 600;
                        color: #2B2B2B;
                        line-height: 0.307rem;
                        margin: 0;
                        padding: 0;
                        margin-bottom: 0.10rem;
                    }
                    p{
                        width: 100%;
                        // height: 0.576rem;
                        font-size: 0.144rem;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #2B2B2B;
                        line-height: 0.192rem;
                        opacity: 0.8;
                    }
                }
                .pic_2_div{
                    position: absolute;
                    // top: 2.266rem;
                    top: 1.982rem;
                    width: 100%;
                    height: 3.072rem;
                    background: url(../assets/m_introduce.png) 100% 100% no-repeat;
                    background-size: cover;
                    img{
                        display: none;
                    }
                }
            }
        }
        .list{
            height: 6.307rem;
            background: #FFDEEF;
            .main{
                width: 100%;
                margin: 0 auto;
                padding-top: 0.24rem;
                h1{
                    width: 100%;
                    height: 0.307rem;
                    margin: 0;
                    padding: 0;
                    font-size: 0.28rem;
                    font-family: Poppins-Bold, Poppins;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 0.307rem;
                }
                .top{
                    margin-top:0.192rem;
                    .item{
                        position: relative;
                        width: 100%;
                        height: 1.68rem;
                        padding: 0 0.154rem;
                        margin-bottom: 0.144rem;
                        border-radius: 16px;
                        .mainlist{
                            position: absolute;
                            top: 0.432rem;
                            left: 0.955rem;
                            width: 2rem;
                        }
                        .mainlistflex{
                            display: flex;
                        }
                        .itemborder{
                            margin-right: 0.0384rem;
                            height: 0.192rem;
                            border-radius: 0.096rem;
                            border: 1px solid #ec9cc4;
                        }
                        .itemlist{
                            display: block;
                            height: 0.192rem;
                            padding: 0 0.056rem;
                            font-size: 0.096rem;
                            font-family: Poppins-Regular, Poppins;
                            font-weight: 500;
                            color: #FF5BAC;
                            line-height: 0.18rem;
                        }
                        .list_1{
                            display: none;
                        }
                        .listbg{
                            display: none;
                        }
                        .list_2{
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0.144rem;
                            width: 1.056rem;
                            height: 1.68rem;
                            z-index: 9;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .bottom{
                            position: relative;
                            width: 3.063rem;
                            height: 1.68rem;
                            margin-left: 0.23rem;
                            background: #FFF3F9;
                            border-radius: 16px;
                            h3{
                                position: absolute;
                                top: 0.144rem;
                                left: 0.97rem;
                                padding: 0;
                                margin: 0;
                                height: .211rem;
                                font-size: 0.144rem;
                                font-family: Poppins-SemiBold, Poppins;
                                font-weight: bold;
                                color: #2B2B2B;
                                line-height: .211rem;
                            }
                            p{
                                position: absolute;
                                top: 0.703rem;
                                left: 0.97rem;
                                width: 1.96rem;
                                height: 0.792rem;
                                font-size: 0.115rem;
                                font-family: Poppins-Regular, Poppins;
                                font-weight: 400;
                                color: #2B2B2B;
                                line-height: 0.168rem;
                                text-align: left;
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
        }
        .story{
            height: 5.02rem;
            background: #FFFFFF;
            .main{
                width: 100%;
                margin: 0 auto;
                position: relative;
                .pic_2_div{
                    display: none;
                }
                .m_pic_2_div{
                    display: block;
                    position: absolute;
                    top: 1.18rem;
                    left: 0;
                    width: 100%;
                    height: 3.84rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .freebg{
                    display: none;
                }
                .m_freebg{
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 2.55rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .top{
                    position: absolute;
                    top: 0.48rem;
                    left: 0;
                    width: 100%;
                    z-index: 9;
                    h2{
                        width: 100%;
                        // height: 0.307rem;
                        padding: 0 .192rem;
                        font-size: 0.28rem;
                        font-family: Poppins-Bold, Poppins;
                        margin: 0;
                        margin-bottom: 0.096rem;
                        font-weight: 600;
                        color: #2B2B2B;
                        line-height: 0.307rem;
                        text-align: center;
                    }
                    p{
                        width: 100%;
                        height: 0.576rem;
                        padding: 0 0.185rem;
                        font-size: 0.144rem;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #2B2B2B;
                        line-height: 0.196rem;
                        text-align: center;
                        opacity: 0.8;
                    }
                }
            }
        }
        .author{
            height: 6.279rem;
            background: #FFDEEF;
            .main{
                width: 100%;
                height: 6.279rem;
                position: relative;
                .pic_2_div{
                    display: none;
                }
                .m_pic_2_div{
                    display: block;
                    position: absolute;
                    top: 4.17rem;
                    left: 0.456rem;
                    width: 2.698rem;
                    height: 1.92rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .aubg{
                    width: 100%;
                    height: 6.279rem;
                    background:url(../assets/m_author_bg.png) 100% 100% no-repeat;
                    background-size: cover;
                }
                .flex_icon{
                    position:relative;
                    width:0.2rem;
                    height: 0.2rem;
                }
                .partin{
                    position: absolute;
                    top: 0.288rem;
                    left: 0;
                    width: 100%;
                    z-index: 9;
                    padding: 0 0.384rem;
                    text-align: center;
                    .icon{
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        width: 0.134rem;
                        height: 0.134rem;
                        margin-right: 0.0384rem;
                        background: url(../assets/author_icon.png) 100% 100% no-repeat;
                        background-size: cover;
                    }
                    h2{
                        width: 100%;
                        height: 0.614rem;
                        margin: 0;
                        // padding: 0 0.1rem;
                        font-size: 0.28rem;
                        font-family: Poppins-Bold, Poppins;
                        font-weight: 600;
                        color: #2B2B2B;
                        line-height: 0.307rem;
                        
                    }
                    p{
                        width: 100%;
                        height: 0.192rem;
                        margin: 0.0192rem 0;
                        font-size: 0.144rem;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #2B2B2B;
                        line-height: 0.192rem;
                        a{
                          color: #2B2B2B;
                        }
                    }
                    .title{
                        width: 100%;
                        height: 0.192rem;
                        font-size: 0.115rem;
                        font-family: Poppins-SemiBold, Poppins;
                        font-weight: 600;
                        color: #FF5BAC;
                        line-height: 0.192rem;
                        margin-top: 0.192rem;
                    }
                    .author_list{
                        display: flex;
                        width: 2.016rem;
                        text-align: left;
                        // margin: auto;
                        margin-left: 0.46rem;
                        height: 100%;
                        p{
                            height: 100%;
                        }
                    }
                    .partbtn{
                        width: 1.92rem;
                        height: 0.384rem;
                        border-radius: 0.211rem;
                        margin: auto;
                        margin-top: 0.192rem;
                        // margin-top: 0.1536rem;
                        background: #FF5BAC;
                        box-shadow: 0px 6px 12px -4px rgba(255, 91, 172, 0.45);
                        font-size: 0.134rem;
                        font-family: Poppins-Medium, Poppins;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 0.384rem;
                        text-align: center;
                    }
                    .partbtn:hover{
                        cursor: pointer;
                    }
                }
            }
        }
        .fotter{
            display: none;
        }
        // .m_fotter{
        //     height: 1.469rem;
        //     background: #2B2B2B;
        //     .main{
        //         width: 100%;
        //         position: relative;
        //         .bot_title{
        //             position: absolute;
        //             top: 0.24rem;
        //             left: 1.416rem;
        //             width: 0.768rem;
        //             height: 0.192rem;
        //             img{
        //                 width: 100%;
        //                 height: 100%;
        //             }
        //         }
        //         .bot_bottom{
        //             position: absolute;                    height: 18px;
        //             top: 1.171rem;
        //             width: 100%;
        //             height: 0.154rem;
        //             font-size: 0.096rem;
        //             font-family: Poppins-Regular, Poppins;
        //             font-weight: 400;
        //             color: #FFFFFF;
        //             line-height: 0.154rem;
        //             opacity: 0.5;
        //             text-align: center;
        //         }
        //         .bot_content{
        //             .bot_email{
        //                 position: absolute;
        //                 top: 0.777rem;
        //                 width: 100%;
        //                 height: 0.154rem;
        //                 font-size: 0.096rem;
        //                 line-height:0.154rem ;
        //                 font-family: Poppins-Regular, Poppins;
        //                 font-weight: 400;
        //                 text-align: center;
        //                 span:nth-child(1){
        //                     margin: 0;
        //                     color: #FFFFFF;
        //                     opacity: .5;
        //                 }
        //                 span:nth-child(2){
        //                     margin: 0;
        //                     color: #FF5BAC;
        //                 }
        //             }
        //             .bot_terms{
        //                 position: absolute;
        //                 top: 0.576rem;
        //                 width: 100%;
        //                 height: 0.154rem;
        //                 font-size:0.096rem;
        //                 font-family: Poppins-Regular, Poppins;
        //                 font-weight: 400;
        //                 color: #FFFFFF;
        //                 line-height: 0.154rem;
        //                 text-align: center;
        //                 opacity: 0.5;
        //             }
        //             .bot_terms span{
        //                 margin: 0 0.048rem;
        //             }
        //             .bot_terms span:hover{
        //                 cursor: pointer;
        //                 text-decoration: underline;
        //                 color: #FF5BAC;
        //             }
        //         }
        //     }
        // }
    }
}
@media (min-width:1201px){
    .home{
        width: 100%;
        overflow: hidden;
        .banner{
            position: relative;
            background: #FFDEEF;
            height: 700px;
            .banner_title{
                position: absolute;
                width: 100%;
                height: 80px;
                background: #FFFFFF;
                opacity: .4;
                z-index: 9;
            }
            .introduce{
                width: 516px;
                position: absolute;
                top: 270px;
                h1{
                    width: 480px;
                    padding: 0;
                    margin: 0;
                    margin-left: -13px;
                    margin-bottom: 24px;
                    height: 56px;
                    font-size: 56px;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 56px;
                }
                p{
                    width: 516px;
                    height: 72px;
                    font-size: 16px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    margin-bottom: 48px;
                    opacity: 0.6;
                }
                .m_download{
                    display: none;
                }
                .download{
                    display: block;
                    font-size: 12px;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: left;
                    .download_ios{
                        display: inline-block;
                        width: 134px;
                        height: 48px;
                        line-height: 48px;
                        background: #000000;
                        border-radius: 24px;
                        text-align: center;
                        img{
                            width: 16px;
                            height: 16px;
                            margin-top: -5px;
                            margin-right: 6px;
                        }
                    }
                    .download_android{
                        display: inline-block;
                        width: 162px;
                        height: 48px;
                        margin-left: 12px;
                        line-height: 48px;
                        background: #09B55F;
                        border-radius: 24px;
                        text-align: center;
                        img{
                            width: 16px;
                            height: 16px;
                            margin-top: -5px;
                            margin-right: 6px;
                        }
                    }
                    .download_android:hover{
                        cursor: pointer;
                    }
                    .download_ios:hover{
                        cursor: pointer;
                    }
                }
            }
            .main{
                width: 1200px;
                margin: 0 auto;
                .top{
                    display: none;
                }
                .pc_top{
                    display: flex;
                    width: 1200px;
                    justify-content: space-between;
                    align-items: center;
                    height: 80px;
                    position: absolute;
                    z-index: 99;
                    .left_logo{
                        display: inline-block;
                        width: 50px;
                        height: 50px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .right_logo{
                        display: inline-block;
                        position: absolute;
                        top: 29px;
                        width: 136px;
                        height: 34px;
                        margin-left: 62px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .contact_us{
                        width: 124px;
                        height: 48px;
                        background: #FF5BAC;
                        border-radius: 24px;
                        font-size: 12px;
                        font-family: Poppins-Medium, Poppins;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 48px;
                        cursor: pointer;
                    }
                }
                .pic_1_div{
                    float: right;
                    width: 1140px;
                    height: 700px;
                    img{
                        width: 100%;
                        height: 100%;
                        margin-left: 322px;
                    }
                }
            }
        }
        .contain{
            background: #FFFFFF;
            height: 700px;
            .main{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                .heart{
                    position: absolute;
                    top: 76px;
                    left: -39px;
                    width: 120px;
                    height: 120px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .introduce{ 
                    position: absolute;
                    width: 565px;
                    top: 210px;
                    text-align: left;
                    .introduce_m{
                        display: none;
                    }
                    .introduce_pc{
                        display: block;
                    }
                    h1{
                        width: 565px;
                        height: 56px;
                        font-size: 48px;
                        font-family: Poppins-SemiBold, Poppins;
                        font-weight: 600;
                        color: #2B2B2B;
                        line-height: 56px;
                        margin: 0;
                        padding: 0;
                        margin-bottom: 16px;
                    }
                    p{
                        width: 547px;
                        height: 18px;
                        font-size: 14px;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #2B2B2B;
                        line-height: 18px;
                        opacity: 0.6;
                    }
                }
                .pic_2_div{
                    width: 756px;
                    height: 700px;
                    margin-left: 522px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .list{
            height: 764px;
            background: #FFDEEF;
            .main{
                width: 1200px;
                margin: 0 auto;
                padding-top: 56px;
                h1{
                    // width: 389px;
                    height: 72px;
                    margin: 0;
                    padding: 0;
                    font-size: 48px;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 72px;
                    text-align: left;
                }
                .top{
                    margin-top: 64px;
                    display: flex;
                    justify-content: space-between;
                    .item{
                        position: relative;
                        width: 320px;
                        height: 510px;
                        .mainlist{
                            display: flex;
                            justify-content: space-around;
                            margin-bottom: 12px;
                            padding: 0 31px;
                            .mainlistflex{
                                display: flex;
                            }
                        }
                        .itemborder{
                            margin-right: 3px;
                            height: 24px;
                            border-radius: 12px;
                            border: 1px solid #FF5BAC;
                        }
                        .itemlist{
                            height: 18px;
                            padding: 0 10px;
                            font-size: 12px;
                            font-family: Poppins-Regular, Poppins;
                            font-weight: 500;
                            color: #FF5BAC;
                            line-height: 18px;
                        }
                        .list_1{
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 60px;
                            width: 200px;
                            height: 300px;
                            background: #FFDEEF;
                            img{
                                width: 100%;
                                height: 100%;
                                box-shadow:1px 10px 25px rgba(231, 62, 180, 0.25);
                            }
                        }
                        .listbg{
                            display: none;
                            position: absolute;
                            top: 300px;
                            left: 60px;
                            width: 200px;
                            height: 20px;
                            border: 1px solid #FFF3F9;
                            border-radius: 10px;
                            box-shadow:0 4px 4px rgba(231, 62, 180, 0.3);
                        }
                        .list_2{
                            display: none;
                        }
                        .bottom{
                            width: 100%;
                            height: 390px;
                            margin-top: 120px;
                            background: #FFF3F9;
                            border-radius: 16px;
                            h3{
                                padding: 0;
                                margin: 0;
                                padding-top: 188px;
                                margin-bottom: 6px;
                                // height: 40px;
                                font-size: 24px;
                                font-family: Poppins-SemiBold, Poppins;
                                font-weight: 600;
                                color: #2B2B2B;
                                line-height: 40px;
                            }
                            p{
                                height: 90px;
                                font-size: 14px;
                                font-family: Poppins-Regular, Poppins;
                                font-weight: 400;
                                color: #2B2B2B;
                                line-height: 18px;
                                padding: 0 31px;
                                text-align: left;
                                opacity: 0.6;
                            }
                        }
                    }
                    .left_logo{
                        display: inline-block;
                        width: 50px;
                        height: 50px;
                        margin-top: 15px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .right_logo{
                        display: inline-block;
                        width: 136px;
                        height: 34px;
                        margin-left: 12px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .pic_2_div{
                    width: 756px;
                    height: 700px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .story{
            height: 700px;
            background: #FFFFFF;
            .main{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                .pic_2_div{
                    display: block;
                    width: 600px;
                    height: 700px;
                    margin-left: -20px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .m_pic_2_div{
                    display: none;
                }
                .freebg{
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 662px;
                    height: 700px;
                    margin-right: -237px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .m_freebg{
                    display: none;
                }
                .top{
                    position: absolute;
                    top: 288px;
                    right: 21px;
                    width: 580px;
                    z-index: 9;
                    h2{
                        width: 578px;
                        // height: 72px;
                        font-size: 48px;
                        margin: 0;
                        margin-bottom: 16px;
                        font-family: Poppins-SemiBold, Poppins;
                        font-weight: 600;
                        color: #2B2B2B;
                        line-height: 72px;
                        text-align: left;
                    }
                    p{
                        width: 541px;
                        height: 36px;
                        font-size: 14px;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #2B2B2B;
                        line-height: 18px;
                        text-align: left;
                        opacity: 0.6;
                    }
                }
            }
        }
        .author{
            height: 700px;
            background: #FFDEEF;
            .main{
                width: 1200px;
                height: 700px;
                margin: 0 auto;
                position: relative;
                .pic_2_div{
                    display: block;
                    position: absolute;
                    top: 130px;
                    right: 83px;
                    width: 670px;
                    height: 478px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .m_pic_2_div{
                    display: none;
                }
                .aubg{
                    display: block;
                    width: 1200px;
                    height: 700px;
                    margin: 0 auto;
                    margin-right: -16px;
                    background:url(../assets/author_bg.png) 100% 100% no-repeat;
                    background-size: cover;
                }
                .author_list{
                    display: flex;
                }
                .flex_icon{
                    position:relative;
                    width:24px;
                    height: 36px;
                }
                .partin{
                    position: absolute;
                    top: 68px;
                    left: 0;
                    width: 431px;
                    z-index: 9;
                    text-align: left;
                    .icon{
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        width: 14px;
                        height: 14px;
                        margin-right: 4px;
                        background: url(../assets/author_icon.png) 100% 100% no-repeat;
                        background-size: cover;
                    }
                    h2{
                        width: 430px;
                        // height: 144px;
                        font-size: 48px;
                        font-family: Poppins-SemiBold, Poppins;
                        font-weight: 600;
                        color: #2B2B2B;
                        line-height: 72px;
                        margin-bottom: 24px;
                    }
                    p{
                        width: 100%;
                        height: 24px;
                        margin: 4px 0;
                        font-size: 14px;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #2B2B2B;
                        line-height: 24px;
                        a{
                            color: #2B2B2B;
                        }
                    }
                    .title{
                        width: 100%;
                        height: 24px;
                        font-size: 14px;
                        font-family: Poppins-SemiBold, Poppins;
                        font-weight: 600;
                        color: #FF5BAC;
                        line-height: 24px;
                        margin-top: 24px;
                    }
                    .partbtn{
                        width: 234px;
                        height: 64px;
                        margin-top: 32px;
                        background: #FF5BAC;
                        box-shadow: 0px 6px 32px -4px rgba(255, 91, 172, 0.45);
                        border-radius: 44px;
                        font-size: 24px;
                        font-family: Poppins-SemiBold, Poppins;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 64px;
                        text-align: center;
                    }
                    .partbtn:hover{
                        cursor: pointer;
                    }
                }
            }
        }
        .fotter{
            display: block;
            height: 256px;
            background: #2B2B2B;
            .main{
                width: 1200px;
                margin: 0 auto;
                padding-top: 40px;
                .bot_title{
                    width: 120px;
                    height: 30px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .bot_bottom{
                    margin-top: 56px;
                    height: 18px;
                    font-size: 12px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 18px;
                    opacity: 0.6;
                    text-align: center;
                }
                .bot_content{
                    margin-top: 16px;
                    display: flex;
                    justify-content: space-between;
                    .bot_des{
                        width: 574px;
                        height: 72px;
                        font-size: 12px;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 18px;
                        opacity: 0.6;
                        text-align: left;
                    }
                    .bot_email{
                        width: 152px;
                        font-size: 12px;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        text-align: left;
                        p:nth-child(1){
                            margin: 0;
                            color: #FFFFFF;
                            opacity: .65;
                        }
                        p:nth-child(2){
                            margin: 0;
                            color: #FF5BAC;
                        }
                    }
                    .bot_terms{
                        width: 283px;
                        height: 18px;
                        font-size: 12px;
                        font-family: Poppins-Regular, Poppins;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 18px;
                        opacity: 0.6;
                    }
                    .bot_terms span{
                        margin: 0 5px;
                    }
                    .bot_terms span:hover{
                        cursor: pointer;
                        color: #FF5BAC;
                        text-decoration:underline;
                    }
                }
            }
        }
        .m_fotter{
            display: none;
        }
    }
}