@media (max-width:1200px){
    .banner1{
        position: relative;
        background: #FFFFFF;
        height: 0.42rem;
        .banner_title{
            position: absolute;
            width: 100%;
            height: 0.42rem;
            background: #FFFFFF;
            border-bottom: 1px solid #FFDEEF;
            opacity: .4;
            z-index: 9;
        }
        .main{
            width: 100%;
            margin: 0 auto;
            position: relative;
            .top{
                display: flex;
                align-items: center;
                width: 1.92rem;
                height: 0.42rem;
                position: absolute;
                float: left;
                // margin-left:-0.653rem;
                z-index: 99;
                .left_logo{
                    display: inline-block;
                    width: 1.1rem;
                    height: 0.278rem;
                    margin-left: 0.192rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .back{
                position: absolute;
                top: 0.058rem;
                right:0.192rem ;
                width: 1.114rem;
                height: 0.307rem;
                background: #FF5BAC;
                border-radius: 0.1535rem;
                font-size: 0.115rem;
                font-family: Poppins-Medium, Poppins;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 0.315rem;
                text-align: center;
                z-index: 99;
            }
            .back:hover{
                cursor: pointer;
            }
            .pic_1_div{
                position: absolute;
                top: 2.88rem;
                left: 0;
                width: 100%;
                height: 2.88rem;
                background: url('../../assets/m_banner.png') 100% 100% no-repeat;
                background-size: cover;
                img{
                    display: none;
                }
            }
        }
    }
    .pc_banner1{
        display: none;
    }
}
@media (min-width:1201px){
    .pc_banner1{
        position: relative;
        width: 1200px;
        margin: auto;
        background: #FFFFFF;
        height: 80px;
        .banner_title{
            position: absolute;
            width: 100%;
            height: 80px;
            background: #FFFFFF;
            border-bottom: 1px solid #FFDEEF;
            opacity: .4;
            z-index: 9;
        }
        .main{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            .top{
                width: 200px;
                height: 80px;
                position: absolute;
                float: left;
                margin-left:-74px;
                z-index: 99;
                .left_logo{
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    margin-top: 15px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .right_logo{
                    display: inline-block;
                    position: absolute;
                    top: 30px;
                    width: 136px;
                    height: 34px;
                    margin-left: 12px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .back{
                position: absolute;
                top: 16px;
                right:0 ;
                width: 124px;
                height: 48px;
                background: #FF5BAC;
                border-radius: 24px;
                font-size: 12px;
                font-family: Poppins-Medium, Poppins;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 48px;
                text-align: center;
                z-index: 99;
            }
            .back:hover{
                cursor: pointer;
            }
        }
    }
    .banner1{
        display: none;
    }
}