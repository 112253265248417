@media (max-width:1200px){
    .fotter{
        display: none;
    }
    .m_fotter{
        height: 1.334rem;
        background: #2B2B2B;
        .main{
            width: 100%;
            position: relative;
            .bot_title{
                position: absolute;
                top: 0.24rem;
                left: 1.416rem;
                width: 0.768rem;
                height: 0.192rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .bot_bottom{
                position: absolute;                    
                height: 18px;
                top: 1.027rem;
                width: 100%;
                height: 0.154rem;
                font-size: 0.096rem;
                font-family: Poppins-Regular, Poppins;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 0.154rem;
                opacity: 0.5;
                text-align: center;
            }
            .bot_content{
                .bot_email{
                    position: absolute;
                    top: 0.777rem;
                    width: 100%;
                    height: 0.154rem;
                    font-size: 0.096rem;
                    line-height:0.154rem ;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    text-align: center;
                    span:nth-child(1){
                        margin: 0;
                        color: #FFFFFF;
                        opacity: .5;
                    }
                    span:nth-child(2){
                        margin: 0;
                        color: #FF5BAC;
                    }
                }
                .bot_terms{
                    position: absolute;
                    top: 0.576rem;
                    width: 100%;
                    height: 0.154rem;
                    font-size:0.096rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 0.154rem;
                    text-align: center;
                    opacity: 0.5;
                }
                .bot_terms span{
                    margin: 0 0.048rem;
                }
                .bot_terms span:hover{
                    cursor: pointer;
                    text-decoration: underline;
                    color: #FF5BAC;
                }
            }
        }
    }
}
@media (min-width:1201px){
    .fotter{
        display: block;
        height: 256px;
        background: #2B2B2B;
        .main{
            width: 1200px;
            margin: 0 auto;
            padding-top: 40px;
            .bot_title{
                width: 120px;
                height: 30px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .bot_bottom{
                margin-top: 56px;
                height: 18px;
                font-size: 12px;
                font-family: Poppins-Regular, Poppins;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 18px;
                opacity: 0.6;
                text-align: center;
            }
            .bot_content{
                margin-top: 16px;
                display: flex;
                justify-content: space-between;
                .bot_des{
                    width: 572px;
                    height: 72px;
                    font-size: 12px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 18px;
                    opacity: 0.6;
                    text-align: left;
                }
                .bot_email{
                    width: 152px;
                    font-size: 12px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    text-align: left;
                    p:nth-child(1){
                        margin: 0;
                        color: #FFFFFF;
                        opacity: .65;
                    }
                    p:nth-child(2){
                        margin: 0;
                        color: #FF5BAC;
                    }
                }
                .bot_terms{
                    width: 283px;
                    height: 18px;
                    font-size: 12px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 18px;
                    opacity: 0.6;
                }
                .bot_terms span{
                    margin: 0 5px;
                }
                .bot_terms span:hover{
                    cursor: pointer;
                    color: #FF5BAC;
                    text-decoration:underline;
                }
            }
        }
    }
    .m_fotter{
        display: none;
    }
}