
@media (max-width:1200px){
    .contactus{
        width: 100%;
        .banner1{
            position: relative;
            background: #FFFFFF;
            height: 0.42rem;
            .banner_title{
                position: absolute;
                width: 100%;
                height: 0.42rem;
                background: #FFFFFF;
                border-bottom: 1px solid #FFDEEF;
                opacity: .4;
                z-index: 9;
            }
            .main{
                width: 100%;
                margin: 0 auto;
                position: relative;
                .top{
                    display: flex;
                    align-items: center;
                    width: 1.92rem;
                    height: 0.42rem;
                    position: absolute;
                    float: left;
                    // margin-left:-0.653rem;
                    z-index: 99;
                    .left_logo{
                        display: inline-block;
                        width: 1.1rem;
                        height: 0.278rem;
                        margin-left: 0.192rem;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .back{
                    position: absolute;
                    top: 0.058rem;
                    right:0.192rem ;
                    width: 1.114rem;
                    height: 0.307rem;
                    background: #FF5BAC;
                    border-radius: 0.1535rem;
                    font-size: 0.115rem;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 0.315rem;
                    text-align: center;
                    z-index: 99;
                }
                .back:hover{
                    cursor: pointer;
                }
                .pic_1_div{
                    position: absolute;
                    top: 2.88rem;
                    left: 0;
                    width: 100%;
                    height: 2.88rem;
                    background: url('../../assets/m_banner.png') 100% 100% no-repeat;
                    background-size: cover;
                    img{
                        display: none;
                    }
                }
            }
        }
        .content1{
            display: none;
            height: 1564px;
            background: #FFFFFF;
            .main{
                position: relative;
                width: 1200px;
                margin: 0 auto;
                background: #FFFFFF;
                padding-top: 100px;
                img{
                    position: absolute;
                    top: 100px;
                    right: 16px;
                    width: 532.64px;
                    height: 380px;
                }
                h2{
                    width: 500px;
                    height: 112px;
                    font-size: 56px;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    margin: 0;
                    color: #2B2B2B;
                    line-height: 56px;
                    text-align: left;
                }
                .title{
                    height: 24px;
                    font-size: 14px;
                    margin: 0;
                    margin-top: 40px;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #FF5BAC;
                    line-height: 24px;
                    text-align: left;
                }
                .p_title{
                    height: 24px;
                    font-size: 16px;
                    margin: 0;
                    margin-top: 11px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: .7;
                }
                .normal{
                    // height: 24px;
                    width: 820px;
                    font-size: 16px;
                    margin: 0;
                    margin-top: 8px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 0.7;
                }
                .p_normal{
                    font-size: 16px;
                    margin: 0;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 0.7;
                }
                .p_weight_title{
                    width: 820px;
                    height: 96px;
                    font-size: 16px;
                    font-family: Poppins-MediumItalic, Poppins;
                    font-weight: normal;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 24px;
                    margin-bottom: 16px;
                }
                .first_normal{
                    width: 820px;
                    height: 72px;
                    margin-top: 8px;
                    font-size: 16px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 0.7;
                }
                .sec_normal{
                    width: 820px;
                    height: 48px;
                    margin-top: 8px;
                    font-size: 16px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 0.7;
                }
            }
        }
        .m_content{
            display: block;
            background: #FFFFFF;
            .main{
                position: relative;
                width: 100%;
                margin: 0 auto;
                background: #FFFFFF;
                padding-top: 0.499rem;
                .first_page{
                    background: #FFFFFF;
                    // height: 4.608rem;
                    padding: 0 0.192rem;
                    padding-bottom: 0.307rem;
                }
                .sec_page{
                    // height: 3.072rem;
                    background: #FFF0F8;
                    padding: 0 0.192rem;
                    padding-top:1px;
                    padding-bottom: 0.307rem;
                }
                .third_page{
                    // height: 4.569rem;
                    // height: 4.389rem;
                    background: #FFFFFF;
                    padding: 0 0.192rem;
                    padding-bottom: 0.307rem;
                }
                .forth_page{
                    // height: 4.99rem;
                    background: #FFF0F8;
                    padding: 1px 0.192rem;
                    padding-bottom: 0.307rem;
                }
                img{
                    width: 2.81rem;
                    height: 2.29rem;
                }
                h2{
                    width: 2.496rem;
                    font-size: 0.28rem;
                    font-family: Poppins-Bold, Poppins;
                    font-weight: normal;
                    color: #2B2B2B;
                    margin: 0;
                    line-height: 0.307rem;
                    text-align: left;
                }
                .title{
                    height: 0.23rem;
                    font-size: 0.144rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #FF5BAC;
                    line-height: 0.23rem;
                    margin: 0;
                    margin-top: 0.307rem;
                    text-align: left;
                    opacity: .8;
                }
                .special_title{
                    margin-top: 0.144rem;
                }
                .re_title{
                    height: 0.23rem;
                    font-size: 0.144rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #FF5BAC;
                    line-height: 0.23rem;
                    margin: 0;
                    margin-top: 0.307rem;
                    text-align: left;
                    opacity: 1;
                    a{
                        color: #FF5BAC;
                    }
                }
                .p_title{
                    height: 0.23rem;
                    margin: 0;
                    margin-top: 0.096rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-size: 0.144rem;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 0.23rem;
                    text-align: left;
                    opacity: 0.8;
                }
                .span_title{
                    height: 0.23rem;
                    margin: 0;
                    margin-top: 0.096rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-size: 0.144rem;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 0.23rem;
                    text-align: left;
                    opacity: 1;
                }
                .normal{
                    font-size: 0.144rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    margin: 0;
                    margin-top: 0.0768rem;
                    margin-bottom: 0.244rem;
                    color: #2B2B2B;
                    line-height: 0.192rem;
                    text-align: left;
                    opacity: .8;
                }
                .go_normal{
                    height: 0.23rem;
                    margin-top: -0.1768rem;
                    margin-bottom: 0.2688rem;
                    font-size: 0.144rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #FF5BAC;
                    line-height: 0.23rem; 
                    text-align: left;
                }
                .p_normal{
                    font-size: 0.144rem;
                    margin: 0;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 0.172rem;
                    text-align: left;
                    opacity: 0.8;
                }
                .p_weight_title{
                    font-size: 0.144rem;
                    font-family: Poppins-SemiBoldItalic, Poppins;
                    font-weight: normal;
                    color: #2B2B2B;
                    line-height: 0.192rem;
                    text-align: left;
                    margin: 0.115rem 0;
                    opacity: .8;
                }
                .first_normal{
                    font-size: 0.144rem;
                    margin: 0;
                    margin-top: 0.0768rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 0.192rem;
                    text-align: left;
                    opacity: 0.8;
                }
                .sec_normal{
                    margin: 0;
                    margin-top: 0.0768rem;
                    font-size: .144rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 0.192rem;
                    text-align: left;
                    opacity: 0.8;
                }
            }
        }
        .fotter{
            display: none;
        }
        // .m_fotter{
        //     height: 1.469rem;
        //     background: #2B2B2B;
        //     .main{
        //         width: 100%;
        //         position: relative;
        //         .bot_title{
        //             position: absolute;
        //             top: 0.24rem;
        //             left: 1.416rem;
        //             width: 0.768rem;
        //             height: 0.192rem;
        //             img{
        //                 width: 100%;
        //                 height: 100%;
        //             }
        //         }
        //         .bot_bottom{
        //             position: absolute;                    height: 18px;
        //             top: 1.171rem;
        //             width: 100%;
        //             height: 0.154rem;
        //             font-size: 0.096rem;
        //             font-family: Poppins-Regular, Poppins;
        //             font-weight: 400;
        //             color: #FFFFFF;
        //             line-height: 0.154rem;
        //             opacity: 0.5;
        //             text-align: center;
        //         }
        //         .bot_content{
        //             .bot_email{
        //                 position: absolute;
        //                 top: 0.777rem;
        //                 width: 100%;
        //                 height: 0.154rem;
        //                 font-size: 0.096rem;
        //                 line-height:0.154rem ;
        //                 font-family: Poppins-Regular, Poppins;
        //                 font-weight: 400;
        //                 text-align: center;
        //                 span:nth-child(1){
        //                     margin: 0;
        //                     color: #FFFFFF;
        //                     opacity: .5;
        //                 }
        //                 span:nth-child(2){
        //                     margin: 0;
        //                     color: #FF5BAC;
        //                 }
        //             }
        //             .bot_terms{
        //                 position: absolute;
        //                 top: 0.576rem;
        //                 width: 100%;
        //                 height: 0.154rem;
        //                 font-size:0.096rem;
        //                 font-family: Poppins-Regular, Poppins;
        //                 font-weight: 400;
        //                 color: #FFFFFF;
        //                 line-height: 0.154rem;
        //                 text-align: center;
        //                 opacity: 0.5;
        //             }
        //             .bot_terms span{
        //                 margin: 0 0.048rem;
        //             }
        //             .bot_terms span:hover{
        //                 cursor: pointer;
        //                 text-decoration: underline;
        //                 color: #FF5BAC;
        //             }
        //         }
        //     }
        // }
    }
}
@media (min-width:1201px){
    .contactus{
        width: 100%;
        overflow: hidden;
        .banner1{
            position: relative;
            background: #FFFFFF;
            height: 80px;
            .banner_title{
                position: absolute;
                width: 100%;
                height: 80px;
                background: #FFFFFF;
                border-bottom: 1px solid #FFDEEF;
                opacity: .4;
                z-index: 9;
            }
            .main{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                .top{
                    width: 200px;
                    height: 80px;
                    position: absolute;
                    float: left;
                    margin-left:-74px;
                    z-index: 99;
                    .left_logo{
                        display: inline-block;
                        width: 50px;
                        height: 50px;
                        margin-top: 15px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .right_logo{
                        display: inline-block;
                        position: absolute;
                        top: 30px;
                        width: 136px;
                        height: 34px;
                        margin-left: 12px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .back{
                    position: absolute;
                    top: 16px;
                    right:16px ;
                    width: 124px;
                    height: 48px;
                    background: #FF5BAC;
                    border-radius: 24px;
                    font-size: 12px;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 48px;
                    text-align: center;
                    z-index: 99;
                }
                .back:hover{
                    cursor: pointer;
                }
            }
        }
        .m_content{
            display: none;
        }
        .content1{
            display: block;
            height: 1000px;
            background: #FFFFFF;
            .main{
                position: relative;
                width: 1200px;
                margin: 0 auto;
                background: #FFFFFF;
                padding-top: 100px;
                img{
                    position: absolute;
                    top: 100px;
                    right: 16px;
                    width: 540px;
                    height: 440px;
                }
                h2{
                    width: 500px;
                    // height: 112px;
                    font-size: 56px;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    margin: 0;
                    color: #2B2B2B;
                    line-height: 56px;
                    text-align: left;
                }
                .title{
                    height: 24px;
                    font-size: 14px;
                    margin: 0;
                    margin-top: 40px;
                    margin-bottom: 4px;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #FF5BAC;
                    line-height: 24px;
                    text-align: left;
                }
                .gs_title{
                    margin-top: -36px;
                }
                .special_color_title{
                    color: #FF5BAC;
                    text-decoration: underline;
                    a{
                        color: #FF5BAC;
                        text-decoration: underline;   
                    }
                }
                .p_title{
                    height: 24px;
                    font-size: 16px;
                    margin: 0;
                    margin-top: 11px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 36px;
                    text-align: left;
                    opacity: .7;
                }
                .span_title{
                    height: 24px;
                    font-size: 16px;
                    margin: 0;
                    margin-top: 11px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 600;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 1;
                }
                .normal{
                    // height: 24px;
                    width: 820px;
                    font-size: 16px;
                    margin: 0;
                    margin-top: 8px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 0.7;
                }
                .p_normal{
                    font-size: 16px;
                    margin: 0;
                    margin-bottom: 40px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 36px;
                    text-align: left;
                    opacity: 0.7;
                }
                .p_weight_title{
                    width: 820px;
                    height: 96px;
                    font-size: 16px;
                    font-family: Poppins-MediumItalic, Poppins;
                    font-weight: normal;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 24px;
                    margin-bottom: 16px;
                }
                .first_normal{
                    width: 820px;
                    height: 72px;
                    margin: 8px 0;
                    font-size: 16px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 0.7;
                }
                .sec_normal{
                    width: 820px;
                    height: 48px;
                    margin-top: 8px;
                    font-size: 16px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 24px;
                    text-align: left;
                    opacity: 0.7;
                }
            }
        }
        .m_fotter{
            display: none;
        }
    }
}